exports.components = {
  "component---src-components-city-js": () => import("./../../../src/components/city.js" /* webpackChunkName: "component---src-components-city-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-e-commerce-js": () => import("./../../../src/pages/e-commerce.js" /* webpackChunkName: "component---src-pages-e-commerce-js" */),
  "component---src-pages-express-js": () => import("./../../../src/pages/express.js" /* webpackChunkName: "component---src-pages-express-js" */),
  "component---src-pages-freelance-js": () => import("./../../../src/pages/freelance.js" /* webpackChunkName: "component---src-pages-freelance-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-link-js": () => import("./../../../src/pages/link.js" /* webpackChunkName: "component---src-pages-link-js" */),
  "component---src-pages-magento-js": () => import("./../../../src/pages/magento.js" /* webpackChunkName: "component---src-pages-magento-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-shopware-js": () => import("./../../../src/pages/shopware.js" /* webpackChunkName: "component---src-pages-shopware-js" */),
  "component---src-pages-socialprojekt-js": () => import("./../../../src/pages/socialprojekt.js" /* webpackChunkName: "component---src-pages-socialprojekt-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-symfony-js": () => import("./../../../src/pages/symfony.js" /* webpackChunkName: "component---src-pages-symfony-js" */),
  "component---src-pages-website-js": () => import("./../../../src/pages/website.js" /* webpackChunkName: "component---src-pages-website-js" */)
}

